import { css } from "@emotion/css";
import { resetStyles } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { QuestionIllustration } from "../QuestionIllustration";
interface NoSearchResultsProps {
    searchInput: string;
}
export function NoSearchResults({ searchInput }: NoSearchResultsProps) {
    return (<div className={noSearchResultsStyles}>
            <QuestionIllustration />
            <h3 className={noSearchResultsHeadingStyles}>No results found</h3>
            <div className={noSearchResultsCopyStyles}>
                Could not find anything for
                <span className={searchQueryContainerStyles}>
                    {`"`}
                    <span className={searchQueryStyles}>{`${searchInput}`}</span>
                    {`"`}
                </span>
                <span className={clearSearchCopyStyles}>Clear search to start again.</span>
            </div>
        </div>);
}
const noSearchResultsStyles = css({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: space[16],
});
const noSearchResultsHeadingStyles = css({
    ...resetStyles.heading,
    font: text.interface.heading.medium,
    color: themeTokens.color.text.primary,
});
const noSearchResultsCopyStyles = css({
    display: "flex",
    flexWrap: "wrap",
    columnGap: space[4],
    justifyContent: "center",
    font: text.interface.body.default.base,
    color: themeTokens.color.text.muted,
    textAlign: "center",
});
const searchQueryContainerStyles = css({
    display: "flex",
    justifyContent: "center",
});
const searchQueryStyles = css({
    font: text.interface.body.bold.base,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: "20rem",
});
const clearSearchCopyStyles = css({
    flexBasis: "100%",
});
