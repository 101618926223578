import type { OctopusTheme } from "@octopusdeploy/design-system-tokens";
import { darkTheme, lightTheme } from "@octopusdeploy/design-system-tokens";
import type { PropsWithChildren } from "react";
import React, { useMemo } from "react";
export interface ThemeProps {
    themeName: ThemeName;
}
export function Theme({ themeName, children }: PropsWithChildren<ThemeProps>) {
    const themeCssVariableProps = useMemo(() => {
        const theme: OctopusTheme = themeName === "light" ? lightTheme : darkTheme;
        return Object.entries(theme).reduce((prev, [cssVariableName, value]) => {
            return {
                ...prev,
                [`--${cssVariableName}`]: value,
            };
        }, {});
    }, [themeName]);
    return <div style={themeCssVariableProps}>{children}</div>;
}
export type ThemeName = "light" | "dark";
