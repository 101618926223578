import { css } from "@emotion/css";
import { borderRadius, themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { resetStyles } from "../../utils/resetStyles";
import { XMarkIcon } from "../Icon/XMarkIcon";
interface ClearInputButtonProps {
    onClear: () => void;
    accessibleName: string;
}
export function ClearInputButton({ onClear, accessibleName }: ClearInputButtonProps) {
    return (<button className={clearInputButtonStyles} onClick={() => onClear()} aria-label={accessibleName}>
            <XMarkIcon size={20}/>
        </button>);
}
const clearInputButtonStyles = css({
    ...resetStyles.button,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: borderRadius.small,
    color: themeTokens.color.menuList.text.primary,
    ":focus-visible": {
        boxShadow: themeTokens.shadow.focus.default,
    },
});
