import { css } from "@emotion/css";
import type { DropdownButtonState } from "@octopusdeploy/design-system-components";
import { borderRadius, themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import type { PropsWithChildren } from "react";
interface ButtonProps {
    className?: string;
    dropdownButtonState: DropdownButtonState;
    accessibleName: string;
    disabled?: boolean;
}
const Button = ({ className, dropdownButtonState, accessibleName, disabled, children }: PropsWithChildren<ButtonProps>) => {
    return (<button className={className} ref={dropdownButtonState.setButtonRef} onClick={disabled ? undefined : dropdownButtonState.toggleDropdown} {...dropdownButtonState.buttonAriaAttributes} aria-label={accessibleName}>
            {children}
        </button>);
};
interface DropdownProps {
    dropdownButtonState: DropdownButtonState;
    accessibleName: string;
}
const Dropdown = ({ dropdownButtonState, accessibleName, children }: PropsWithChildren<DropdownProps>) => {
    return (<div ref={dropdownButtonState.setDropdownRef} id={dropdownButtonState.dropdownAttributes.dropdownId} className={dropdownOverlayStyles} style={dropdownButtonState.dropdownAttributes.dropdownPositionStyles} {...dropdownButtonState.dropdownAttributes.dropdownAriaAttributes} aria-label={accessibleName}>
            <div className={dropdownContainerStyles}>{children}</div>
        </div>);
};
export const DropdownButton = {
    Dropdown,
    Button,
};
const dropdownOverlayStyles = css({
    top: "1rem",
    bottom: "1rem",
    left: "1rem",
    right: "1rem",
    pointerEvents: "none",
    zIndex: 1,
});
const dropdownContainerStyles = css({
    position: "relative",
    background: themeTokens.color.menuList.background.default,
    borderRadius: borderRadius.medium,
    boxShadow: themeTokens.shadow.medium,
    maxWidth: "29rem",
    height: "33.75rem",
    pointerEvents: "all",
});
