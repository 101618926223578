import { css } from "@emotion/css";
import { XMarkIcon, CircleExclamationIcon, resetStyles } from "@octopusdeploy/design-system-components";
import { borderRadius, space, colorScales, text } from "@octopusdeploy/design-system-tokens";
import { OctopusError } from "@octopusdeploy/octopus-server-client";
import React from "react";
interface ErrorAlertProps {
    error: Error;
    onClose: () => void;
    accessibleName: string;
}
export function ErrorAlert({ error, onClose, accessibleName }: ErrorAlertProps) {
    const isOctopusError = OctopusError.isOctopusError(error);
    const title = isOctopusError ? error.ErrorMessage : error.message;
    const details = isOctopusError ? error.Errors : undefined;
    return (<div className={containerStyles} role="alert" aria-label={accessibleName}>
            <CircleExclamationIcon />
            <p className={errorTitleStyles}>{title}</p>
            <button className={closeAlertButtonStyles} onClick={onClose} aria-label={`Close ${accessibleName} alert`}>
                <XMarkIcon />
            </button>
            {details && details.length > 0 && (<div className={errorDetailContainerStyles}>
                    {details.map((detail, index) => (<p className={errorDetailStyles} key={index}>
                            {detail}
                        </p>))}
                </div>)}
        </div>);
}
const accentWidth = space[8];
const accentColor = colorScales.red[500];
const backgroundColor = colorScales.red[100];
const containerStyles = css({
    display: "grid",
    width: "100%",
    boxSizing: "border-box",
    padding: space[16],
    gap: space[8],
    borderRadius: borderRadius.large,
    background: `linear-gradient(90deg, ${accentColor} 0, ${accentColor} ${accentWidth}, ${backgroundColor} ${accentWidth}, ${backgroundColor} 100%)`,
    gridTemplateColumns: "auto 1fr auto",
});
const errorTitleStyles = css({
    ...resetStyles.paragraph,
    font: text.interface.body.bold.base,
    color: colorScales.red[600],
});
const errorDetailContainerStyles = css({
    display: "flex",
    flexDirection: "column",
    gap: space[8],
    gridColumn: 2,
});
const errorDetailStyles = css({
    ...resetStyles.paragraph,
    font: text.interface.body.default.base,
    color: colorScales.navy[900],
});
const closeAlertButtonStyles = css({
    ...resetStyles.button,
    display: "flex",
});
