import { css } from "@emotion/css";
import React from "react";
import { iconStyles } from "./iconStyles";
export function MagnifyingGlassIcon() {
    return (<svg className={magnifyingGlassIconStyles} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <path d="M35.6875 34.3125L26.8125 25.5C28.8125 23.1875 29.9375 20.25 29.9375 17C29.9375 9.875 24.0625 4 16.9375 4C9.75 4 4 9.875 4 17C4 24.1875 9.8125 30 16.9375 30C20.125 30 23.0625 28.875 25.375 26.875L34.1875 35.75C34.4375 35.9375 34.6875 36 35 36C35.25 36 35.5 35.9375 35.6875 35.75C36.0625 35.375 36.0625 34.6875 35.6875 34.3125ZM17 28C10.875 28 6 23.0625 6 17C6 10.9375 10.875 6 17 6C23.0625 6 28 10.9375 28 17C28 23.125 23.0625 28 17 28Z"/>
        </svg>);
}
const magnifyingGlassIconStyles = css(iconStyles, {
    width: 20,
    height: 20,
});
