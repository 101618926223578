import { css } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { iconStyles } from "./iconStyles";
export function CaretUpIcon() {
    return (<svg className={styles} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0142 26H28.0274C29.7857 26 30.6648 23.8722 29.4089 22.6206L21.4337 14.6102C20.6801 13.7966 19.3614 13.7966 18.6078 14.6102L10.6326 22.6206C9.31391 23.8722 10.1931 26 12.0142 26Z"/>
        </svg>);
}
const styles = css(iconStyles, {
    width: 20,
    height: 20,
    fill: themeTokens.color.icon.muted,
});
