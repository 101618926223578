import { css } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { iconStyles } from "./iconStyles";
export function GitTagIcon() {
    return (<svg className={styles} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.6625 6.34998C19.725 6.34998 20.725 6.78653 21.475 7.5349L32.475 18.511C34.0375 20.0701 34.0375 22.6271 32.475 24.1862L24.1625 32.4806C22.6 34.0398 20.0375 34.0398 18.475 32.4806L7.47498 21.5045C6.72498 20.7561 6.34998 19.7583 6.34998 18.6981V9.34346C6.34998 7.72199 7.66248 6.34998 9.34998 6.34998H18.6625ZM9.34998 18.6981C9.34998 18.9476 9.41248 19.197 9.59998 19.3841L20.6 30.3603C20.975 30.7344 21.6625 30.7344 22.0375 30.3603L30.35 22.0658C30.7875 21.6916 30.7875 21.0056 30.35 20.6314L19.35 9.65529C19.1625 9.46819 18.9125 9.34346 18.6625 9.34346H9.34998V18.6981ZM13.35 11.3391C14.4125 11.3391 15.35 12.2746 15.35 13.3348C15.35 14.4573 14.4125 15.3304 13.35 15.3304C12.225 15.3304 11.35 14.4573 11.35 13.3348C11.35 12.2746 12.225 11.3391 13.35 11.3391Z"/>
        </svg>);
}
const styles = css(iconStyles, {
    width: 20,
    height: 20,
    fill: themeTokens.color.icon.muted,
});
