import React from "react";
import type { PageDefinition } from "./pageId";
export interface PageDetailProps {
    page: PageDefinition;
}
const CurrentPageContext = React.createContext<PageDefinition | undefined>(undefined);
const SetCurrentPageContext = React.createContext<((page: PageDefinition) => void) | undefined>(undefined);
interface PageProviderProps {
    initialPage: PageDefinition;
}
export function PageProvider({ children, initialPage }: React.PropsWithChildren<PageProviderProps>) {
    const [page, setPage] = React.useState<PageDefinition>(initialPage);
    return (<SetCurrentPageContext.Provider value={setPage}>
            <CurrentPageContext.Provider value={page}>{children}</CurrentPageContext.Provider>
        </SetCurrentPageContext.Provider>);
}
PageProvider.displayName = "PageProvider";
interface PageChangedProps {
    onChange: (page: PageDefinition) => void;
}
export function PageChanged({ onChange }: PageChangedProps) {
    const page = usePage();
    const onChangeRef = React.useRef(onChange);
    //We want the latest of the callback for when we notify of page changes, but
    //we do not want the callback to trigger the actual page change effect.
    React.useEffect(() => {
        onChangeRef.current = onChange;
    }, [onChange]);
    React.useEffect(() => {
        if (page) {
            onChangeRef.current(page);
        }
    }, [page]);
    return null;
}
interface PageProps {
    area: string; // Name describing the area of this page. E.g. Library.
    id: string; // Short ID representing a given page. E.g. LibraryTenantTagSetCreate"
    name: string; // Human-readable name describing the page. E.g. "Add Tenant Tag Set"
}
export function Page({ area, id, name, children }: React.PropsWithChildren<PageProps>) {
    const setPage = useSetPage();
    React.useEffect(() => {
        const definition: PageDefinition = {
            Area: area,
            Id: id,
            Name: name,
        };
        setPage(definition);
    }, [area, id, name, setPage]);
    return <>{children}</>;
}
function useSetPage() {
    const setPage = React.useContext(SetCurrentPageContext);
    if (!setPage) {
        throw new Error("The Current Page context has not been setup and an attempt was made to change the page. Please check the component hierarchy and ensure the appropriate context exists above this component.");
    }
    return setPage;
}
export function usePage() {
    const page = React.useContext(CurrentPageContext);
    if (!page) {
        throw new Error("The Current Page context has not been setup. Please check the component hierarchy and ensure the appropriate context exists above this component.");
    }
    return page;
}
