import { css } from "@emotion/css";
import { borderWidth, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ReactNode } from "react";
import React from "react";
export const columnSizes = ["x-small", "small", "medium", "large", "x-large"] as const;
export type ColumnSize = (typeof columnSizes)[number];
export interface DataTableColumn<TData> {
    title: string;
    render: (item: TData) => ReactNode;
    columnSize?: ColumnSize;
    accessibleName?: string;
}
export interface SimpleDataTableProps<TData> {
    columns: DataTableColumn<TData>[];
    data: TData[];
    getRowKey: (item: TData) => React.Key;
    accessibleName?: string;
}
export function SimpleDataTable<TData>({ columns, data, getRowKey, accessibleName }: SimpleDataTableProps<TData>) {
    const headings = columns.map((c) => (<th key={c.title} className={getColumnSizeClass(c.columnSize)} aria-label={c.accessibleName}>
            {c.title}
        </th>));
    const rows = data.map((d) => (<tr key={getRowKey(d)}>
            {columns.map((c, i) => (<td key={i} className={getColumnSizeClass(c.columnSize)} aria-label={c.accessibleName}>
                    {c.render(d)}
                </td>))}
        </tr>));
    return (<table className={tableStyles} aria-label={accessibleName}>
            <thead>
                <tr>{headings}</tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>);
}
function getColumnSizeClass(columnSize?: ColumnSize) {
    switch (columnSize) {
        case "x-small":
            return columnSizeClasses.xSmall;
        case "small":
            return columnSizeClasses.small;
        case "medium":
            return columnSizeClasses.medium;
        case "large":
            return columnSizeClasses.large;
        default:
            return columnSizeClasses.base;
    }
}
const columnSizeClasses = {
    xSmall: css({ width: "5rem" }),
    small: css({ width: "10rem" }),
    medium: css({ width: "20rem" }),
    large: css({ width: "40rem" }),
    base: css({ width: "min-content" }),
};
const tableBorder = `${borderWidth[1]} solid ${themeTokens.color.border.primary}`;
const tableStyles = css({
    width: "100%",
    backgroundColor: themeTokens.color.background.primary.default,
    borderSpacing: 0,
    th: {
        padding: `${space[12]} ${space[16]}`,
        textAlign: "left",
        font: text.table.cell.default.medium,
        position: "relative",
        ":not(:last-child)": {
            ":after": {
                content: "''",
                position: "absolute",
                height: "50%",
                right: 0,
                top: "25%",
                borderRight: tableBorder,
            },
        },
    },
    td: {
        borderTop: tableBorder,
        font: text.table.cell.default.base,
        padding: `${space[8]} ${space[12]}`,
        verticalAlign: "middle",
    },
});
