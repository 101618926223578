import { useAnalyticSession } from "@octopusdeploy/portal-analytics";
import type { PageDefinition } from "@octopusdeploy/portal-routes";
import { usePage } from "@octopusdeploy/portal-routes";
import React, { useCallback, useEffect, useState } from "react";
const SpaceSwitcherInteractionEvent = "Space Switcher";
export const spaceSwitcherInteractionType = {
    open: "open",
    close: "close",
    addNewSpace: "add new space",
    searchStarted: "search started",
    spaceSelected: "space selected",
} as const;
type SpaceSwitcherInteractionType = (typeof spaceSwitcherInteractionType)[keyof typeof spaceSwitcherInteractionType];
export function useDispatchSpaceSwitcherInteraction() {
    const session = useAnalyticSession();
    const page = usePage();
    return React.useCallback((interaction: SpaceSwitcherInteractionType) => session.track(SpaceSwitcherInteractionEvent, mapOptionsToAnalyticFields(interaction, page)), [session, page]);
}
export function useTrackSpaceSwitcherOpenEffect(open: boolean) {
    const dispatchSpaceSwitcherDispatch = useDispatchSpaceSwitcherInteraction();
    //We don't really want the effect to fire for the initial render as it isn't a user interaction
    //otherwise we would capture close events for something the user never did. We therefore track
    //the previous state which is initially undefined to work out whether we should dispatch an event.
    const previousIsOpen = usePrevious(open);
    React.useEffect(() => {
        if (previousIsOpen === undefined || open === previousIsOpen) {
            return;
        }
        if (open) {
            dispatchSpaceSwitcherDispatch(spaceSwitcherInteractionType.open);
        }
        else {
            dispatchSpaceSwitcherDispatch(spaceSwitcherInteractionType.close);
        }
    }, [dispatchSpaceSwitcherDispatch, previousIsOpen, open]);
}
export function useInstrumentAddNewSpaceWithAnalytics(callback: () => void) {
    const dispatchSpaceSwitcherDispatch = useDispatchSpaceSwitcherInteraction();
    return useCallback(() => {
        dispatchSpaceSwitcherDispatch(spaceSwitcherInteractionType.addNewSpace);
        callback();
    }, [dispatchSpaceSwitcherDispatch, callback]);
}
export function useTrackSpaceSwitcherStartedSearchEffect(searchValue: string, isOpen: boolean) {
    const dispatchSpaceSwitcherDispatch = useDispatchSpaceSwitcherInteraction();
    const hasStartedSearching = useHasStartedSearching(searchValue, isOpen);
    React.useEffect(() => {
        if (hasStartedSearching) {
            dispatchSpaceSwitcherDispatch(spaceSwitcherInteractionType.searchStarted);
        }
    }, [dispatchSpaceSwitcherDispatch, hasStartedSearching]);
}
function useHasStartedSearching(searchValue: string, isOpen: boolean) {
    const [hasSearched, setHasSearched] = useState(searchValue !== "");
    useEffect(() => {
        setHasSearched((prev) => {
            if (isOpen) {
                return prev ? prev : searchValue !== "";
            }
            return false;
        });
    }, [isOpen, searchValue]);
    return hasSearched;
}
export function useTrackSpaceSelectedCallback() {
    const dispatchSpaceSwitcherInteraction = useDispatchSpaceSwitcherInteraction();
    return React.useCallback(() => {
        dispatchSpaceSwitcherInteraction(spaceSwitcherInteractionType.spaceSelected);
    }, [dispatchSpaceSwitcherInteraction]);
}
function usePrevious<T>(value: T) {
    const ref = React.useRef<T | undefined>(undefined);
    React.useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}
interface SpaceSwitcherInteractionAnalyticFields {
    "Page Area": string;
    "Page Name": string;
    Interaction: SpaceSwitcherInteractionType;
}
function mapOptionsToAnalyticFields(interaction: SpaceSwitcherInteractionType, page: PageDefinition): SpaceSwitcherInteractionAnalyticFields {
    return {
        "Page Area": page.Area,
        "Page Name": page.Name,
        Interaction: interaction,
    };
}
