import { useAnalyticSession } from "@octopusdeploy/portal-analytics";
import type { PageDefinition } from "@octopusdeploy/portal-routes";
import { usePage } from "@octopusdeploy/portal-routes";
import React from "react";
const ProjectSwitcherInteractionEvent = "Project Switcher";
export const projectSwitcherInteraction = {
    open: "open",
    close: "close",
    searchStarted: "search started",
    projectSelected: "project selected",
} as const;
type ProjectSwitcherInteractionType = (typeof projectSwitcherInteraction)[keyof typeof projectSwitcherInteraction];
export function useDispatchProjectSwitcherInteraction() {
    const session = useAnalyticSession();
    const page = usePage();
    return React.useCallback((interaction: ProjectSwitcherInteractionType) => session.track(ProjectSwitcherInteractionEvent, mapOptionsToAnalyticFields(interaction, page)), [session, page]);
}
export function useTrackProjectSelectedCallback() {
    const dispatchProjectSwitcherInteraction = useDispatchProjectSwitcherInteraction();
    return React.useCallback(() => {
        dispatchProjectSwitcherInteraction(projectSwitcherInteraction.projectSelected);
    }, [dispatchProjectSwitcherInteraction]);
}
export function useTrackProjectSearchStartedCallback() {
    const dispatchProjectSwitcherInteraction = useDispatchProjectSwitcherInteraction();
    const [hasTrackedInteraction, setHasTrackedInteraction] = React.useState(false);
    return React.useCallback(() => {
        if (!hasTrackedInteraction) {
            dispatchProjectSwitcherInteraction(projectSwitcherInteraction.searchStarted);
            setHasTrackedInteraction(true);
        }
    }, [dispatchProjectSwitcherInteraction, hasTrackedInteraction]);
}
interface ProjectSwitcherInteractionAnalyticFields {
    "Page Area": string;
    "Page Name": string;
    Interaction: ProjectSwitcherInteractionType;
}
function mapOptionsToAnalyticFields(interaction: ProjectSwitcherInteractionType, page: PageDefinition): ProjectSwitcherInteractionAnalyticFields {
    return {
        "Page Area": page.Area,
        "Page Name": page.Name,
        Interaction: interaction,
    };
}
