import React from "react";
import type { SimpleMenuItem } from "../MenuItems/SimpleMenuItems";
import { SimpleMenuItems } from "../MenuItems/SimpleMenuItems";
import { CustomMenu } from "./CustomMenu";
import type { MenuState } from "./CustomMenu";
export interface SimpleMenuProps {
    menuState: MenuState;
    items: SimpleMenuItem[];
    accessibleName: string;
    compact?: boolean;
}
export function SimpleMenu({ menuState, items, accessibleName, compact }: SimpleMenuProps) {
    return (<CustomMenu menuState={menuState} accessibleName={accessibleName}>
            <SimpleMenuItems items={items} onClose={menuState.onClose} autoFocus={true} compact={compact}/>
        </CustomMenu>);
}
SimpleMenu.displayName = "SimpleMenu";
