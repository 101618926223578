import { useAnalyticLinkLocation, useAnalyticSession } from "@octopusdeploy/portal-analytics";
import { usePage } from "../page/Page";
import type { PageFields } from "./PageEventDispatcher";
import { getPageFields } from "./PageEventDispatcher";
export interface LinkEvent {
    linkLabel: string;
    documentUrl: string | undefined;
}
export interface LinkFields extends PageFields {
    "Document URL"?: string;
    "Link Label": string;
    "Link Location": string;
    "Page Area": string;
    "Page Name": string;
}
export type AnalyticsLinkClickedDispatechr = (name: string, event: LinkEvent) => void;
export function useDispatchLinkClicked(): AnalyticsLinkClickedDispatechr {
    const session = useAnalyticSession();
    const linkLocation = useAnalyticLinkLocation();
    const page = usePage();
    return (name: string, event: LinkEvent) => {
        if (!page) {
            return;
        }
        const fields: LinkFields = {
            "Link Label": event.linkLabel,
            "Link Location": linkLocation ?? "",
            ...getPageFields(page),
        };
        if (event.documentUrl) {
            fields["Document URL"] = event.documentUrl;
        }
        session.track(name, fields);
    };
}
export function nameForLink() {
    return `Navigate to Page`;
}
