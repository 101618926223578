import type { OctopusTheme } from "@octopusdeploy/design-system-tokens";
import { darkTheme, lightTheme } from "@octopusdeploy/design-system-tokens";
import { useEffect } from "react";
import type { ThemeName } from "./Theme";
export function useApplyGlobalThemeEffect(themeName: ThemeName) {
    useEffect(() => {
        const theme: OctopusTheme = themeName === "light" ? lightTheme : darkTheme;
        const themeCssVariableProps = Object.entries(theme).map(([cssVariableName, value]) => `--${cssVariableName}: ${value};`);
        const serializedCss = `:root {
${themeCssVariableProps.join("\n")}
}`;
        // We manually inject the styles ourselves rather than using injectGlobals from emotion
        // injectGlobals does not have a way to remove or replace previously injected styles, so changing themes causes a new global style to be added
        let styleElement = document.querySelector(`#${globalStyleNodeId}`);
        if (styleElement) {
            styleElement.innerHTML = serializedCss;
        }
        else {
            styleElement = document.createElement("style");
            styleElement.setAttribute("id", globalStyleNodeId);
            styleElement.innerHTML = serializedCss;
            document.head.appendChild(styleElement);
        }
    }, [themeName]);
}
const globalStyleNodeId = "octopus-global-theme";
