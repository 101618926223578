import type { UserResource } from "@octopusdeploy/octopus-server-client";
import type { PropsWithChildren } from "react";
import React, { useContext } from "react";
export function useOctopusSessionUser() {
    const currentUser = useContext(OctopusSessionUserContext);
    if (currentUser === undefined) {
        throw new Error("The current user session context was not provided via OctopusSessionUserProvider");
    }
    if (currentUser === null) {
        throw new Error("A user for the session is required but was null.");
    }
    return currentUser;
}
export interface OctopusSessionUserProviderProps {
    currentUser: UserResource | null;
}
export function OctopusSessionUserProvider({ currentUser, children }: PropsWithChildren<OctopusSessionUserProviderProps>) {
    return <OctopusSessionUserContext.Provider value={currentUser}>{children}</OctopusSessionUserContext.Provider>;
}
const OctopusSessionUserContext = React.createContext<UserResource | null | undefined>(undefined);
