import type { Client } from "../client";
import type { InsightsLicenseCheckResource } from "../resources/insightsLicenseCheckResource";
import type { GetInsightsForReportBffResponse } from "../resources/insightsReportBffResponse";
import type { InsightsReportResource, NewInsightsReportResource } from "../resources/insightsReportResource";
import { BasicRepository } from "./basicRepository";
export enum InsightsMetricsSplit {
    None = "None",
    Project = "Project",
    ProjectGroup = "ProjectGroup",
    Environment = "Environment",
    EnvironmentGroup = "EnvironmentGroup",
    Tenant = "Tenant",
    TenantTagSet = "TenantTagSet"
}
export enum InsightsTimeRange {
    LastYear = "LastYear",
    LastQuarter = "LastQuarter",
    LastMonth = "LastMonth"
}
export enum InsightsGranularity {
    Monthly = "Monthly",
    Weekly = "Weekly",
    Daily = "Daily"
}
export class InsightsReportRepository extends BasicRepository<InsightsReportResource, NewInsightsReportResource> {
    constructor(client: Client) {
        super("InsightsReports", client);
    }
    getInsightsLicenseCheck(): Promise<InsightsLicenseCheckResource> {
        return this.client.get("~/bff/insights/reports/license-check");
    }
    async bff(report: InsightsReportResource, split: InsightsMetricsSplit, timeRange: InsightsTimeRange, granularity: InsightsGranularity, tenantTagSetId?: string): Promise<GetInsightsForReportBffResponse> {
        return await this.client.get<GetInsightsForReportBffResponse>("~/bff/spaces/{spaceId}/insights/reports/{reportId}{?split,granularity,timeRange,tenantTagSetId}", {
            spaceId: report.SpaceId,
            reportId: report.Id,
            split,
            tenantTagSetId,
            timeRange,
            granularity,
        });
    }
    async requestTrial() {
        return await this.client.post("~/bff/insights/request-insights-trial");
    }
}
