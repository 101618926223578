export enum ControlType {
    SingleLineText = "SingleLineText",
    MultiLineText = "MultiLineText",
    Select = "Select",
    Checkbox = "Checkbox",
    Sensitive = "Sensitive",
    StepName = "StepName",
    AzureAccount = "AzureAccount",
    Certificate = "Certificate",
    WorkerPool = "WorkerPool",
    AmazonWebServicesAccount = "AmazonWebServicesAccount",
    UsernamePasswordAccount = "UsernamePasswordAccount",
    GoogleCloudAccount = "GoogleCloudAccountAccount",
    Package = "Package",
    Custom = "Custom"
}
