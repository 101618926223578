import { css } from "@emotion/css";
import { themeTokens, space, text } from "@octopusdeploy/design-system-tokens";
import React, { Fragment } from "react";
import type { DesignSystemLinkHref } from "../../routing/OctopusRoutingContext";
import { useOctopusLinkComponent } from "../../routing/OctopusRoutingContext";
import { ArrowLeftIcon } from "../Icon/index";
export interface BreadcrumbItem {
    /** The label of a breadcrumb link */
    label: string;
    /** The URL of the page that the breadcrumb routes to */
    pageUrl: DesignSystemLinkHref;
}
export interface BreadcrumbsProps {
    /** Items to be combined with Slash to form breadcrumbs */
    items: BreadcrumbItem[];
    /** If true, apply back icon at the beginning of the breadcrumbs */
    showBackIcon?: boolean;
}
export function Breadcrumbs({ items, showBackIcon }: BreadcrumbsProps) {
    const Link = useOctopusLinkComponent();
    const lastItemIndex = items.length - 1;
    return (<nav>
            <ol className={breadcrumbsStyles}>
                {items.map((item, index) => (<Fragment key={index}>
                        <li>
                            <Link className={breadcrumbItemStyles} href={item.pageUrl}>
                                {showBackIcon && index === 0 && <ArrowLeftIcon />}
                                <BreadcrumbTitle>{item.label}</BreadcrumbTitle>
                            </Link>
                        </li>
                        {index !== lastItemIndex && (<li>
                                <span role={"separator"} className={separatorStyles}>
                                    /
                                </span>
                            </li>)}
                    </Fragment>))}
            </ol>
        </nav>);
}
function BreadcrumbTitle({ children }: React.PropsWithChildren<{}>) {
    return <span>{children}</span>;
}
const breadcrumbTitleStyles = css({
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
    display: "inline-block",
});
const breadcrumbsStyles = css({
    listStyle: "none",
    font: text.breadcrumb.small,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    margin: 0,
    padding: 0,
    gap: space["8"],
});
const separatorStyles = css({
    userSelect: "none",
    color: themeTokens.color.text.subtle,
});
const breadcrumbItemStyles = css({
    display: "flex",
    alignItems: "center",
    gap: space["4"],
    textDecoration: "none",
    color: themeTokens.color.text.link.default,
});
