import { useDropdownButton } from "@octopusdeploy/design-system-components";
import { useOctopusRepository, useExperimentalQuery } from "@octopusdeploy/octopus-react-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { LicenseStatusResource, SpaceResource } from "@octopusdeploy/octopus-server-client";
import { useOctopusSessionPermissions, useOctopusSessionUser } from "@octopusdeploy/session";
import React, { useMemo } from "react";
import type { SpaceCreationRestrictionValue } from "./ControlledSpaceSwitcher";
import { ControlledSpaceSwitcher, SpaceCreationRestriction } from "./ControlledSpaceSwitcher";
export interface SpaceSwitcherProps {
    currentSpace: SpaceResource | undefined;
    onNewSpaceRequested: () => void;
    onUnlockMoreSpacesRequested: () => void;
    getSpaceHref: (space: SpaceResource) => string;
}
export function SpaceSwitcher({ currentSpace, onNewSpaceRequested, onUnlockMoreSpacesRequested, getSpaceHref }: SpaceSwitcherProps) {
    const [currentError, setCurrentError] = React.useState<Error>();
    const dropdownMenuState = useDropdownButton({ trapFocus: true, dropdownAriaRole: "dialog" });
    const repository = useOctopusRepository();
    const currentPermissions = useOctopusSessionPermissions();
    const { refetch: refetchSpaces, spaces, isLoading, error } = useSpaces();
    const { data: licenseStatus } = useExperimentalQuery(() => repository.Licenses.getCurrentStatus(), [repository.Licenses]);
    const hasSpaceCreatePermission = currentPermissions.scopeToSpaceAndSystem(currentSpace?.Id ?? null).isAuthorized({
        permission: Permission.SpaceCreate,
    });
    const isWithinLicenseLimit = licenseStatus ? spacesIsWithinLicenceLimit(licenseStatus) : false;
    const restriction: SpaceCreationRestrictionValue | undefined = !hasSpaceCreatePermission ? SpaceCreationRestriction.NoPermission : !isWithinLicenseLimit ? SpaceCreationRestriction.LicenseLimitReached : undefined;
    React.useEffect(() => {
        setCurrentError(dropdownMenuState.isOpen ? error : undefined);
    }, [error, dropdownMenuState.isOpen]);
    const clearCurrentError = () => setCurrentError(undefined);
    return (<ControlledSpaceSwitcher currentSpace={currentSpace} spaces={spaces ?? []} isLoading={isLoading} onNewSpaceRequested={onNewSpaceRequested} onUnlockMoreSpacesRequested={onUnlockMoreSpacesRequested} getSpaceHref={getSpaceHref} onFetchSpacesRequested={refetchSpaces} dropdownButtonState={dropdownMenuState} spaceCreationRestriction={restriction} error={currentError} onCloseError={clearCurrentError}/>);
}
SpaceSwitcher.Controlled = ControlledSpaceSwitcher;
function spacesIsWithinLicenceLimit(licenseStatus: LicenseStatusResource): boolean {
    const spacesLimitStatus = licenseStatus.Limits.find((l) => l.Name === "Spaces");
    return spacesLimitStatus ? spacesLimitStatus.CurrentUsage < spacesLimitStatus.EffectiveLimit : false;
}
function useSpaces() {
    const repository = useOctopusRepository();
    const currentUser = useOctopusSessionUser();
    const { refetch, data, isLoading, error } = useExperimentalQuery(() => repository.Users.getSpaces(currentUser), [currentUser, repository.Users]);
    const spaces = useMemo(() => {
        if (!data) {
            return undefined;
        }
        return [...data].sort((spaceA, spaceB) => {
            const nameA = spaceA.Name.toLowerCase();
            const nameB = spaceB.Name.toLowerCase();
            if (nameA < nameB)
                return -1;
            if (nameB < nameA)
                return 1;
            return 0;
        });
    }, [data]);
    return { refetch, spaces, isLoading, error };
}
