import { css } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { iconStyles } from "./iconStyles";
export function CaretDownIcon() {
    return (<svg className={styles} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.0085 14H12.029C10.2117 14 9.33442 16.179 10.5877 17.4241L18.5461 25.393C19.2981 26.2023 20.6141 26.2023 21.366 25.393L29.3244 17.4241C30.7031 16.179 29.8258 14 28.0085 14Z"/>
        </svg>);
}
const styles = css(iconStyles, {
    width: 20,
    height: 20,
    fill: themeTokens.color.icon.muted,
});
