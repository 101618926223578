import { css } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { iconStyles } from "./iconStyles";
interface GitBranchIconProps {
    emphasis: "muted" | "subtle";
}
export function GitBranchIcon({ emphasis }: GitBranchIconProps) {
    const styles = emphasis === "muted" ? mutedStyles : subtleStyles;
    return (<svg className={styles} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.7711 12.5C10.134 14.5286 8.23882 16 6 16C3.23858 16 1 13.7614 1 11C1 8.23858 3.23858 6 6 6C8.23882 6 10.134 7.47145 10.7711 9.5H14C18.1421 9.5 21.5 12.8579 21.5 17V23C21.5 25.4853 23.5147 27.5 26 27.5H29.2289C29.866 25.4714 31.7612 24 34 24C36.7614 24 39 26.2386 39 29C39 31.7614 36.7614 34 34 34C31.7612 34 29.866 32.5286 29.2289 30.5H26C21.8579 30.5 18.5 27.1421 18.5 23V17C18.5 14.5147 16.4853 12.5 14 12.5H10.7711ZM8 11C8 12.1046 7.10457 13 6 13C4.89543 13 4 12.1046 4 11C4 9.89543 4.89543 9 6 9C7.10457 9 8 9.89543 8 11ZM36 29C36 30.1046 35.1046 31 34 31C32.8954 31 32 30.1046 32 29C32 27.8954 32.8954 27 34 27C35.1046 27 36 27.8954 36 29Z"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M23.5 11C23.5 10.1716 24.1716 9.5 25 9.5L29.2289 9.5C29.866 7.47145 31.7612 6 34 6C36.7614 6 39 8.23858 39 11C39 13.7614 36.7614 16 34 16C31.7612 16 29.866 14.5286 29.2289 12.5L25 12.5C24.1716 12.5 23.5 11.8284 23.5 11ZM34 13C35.1046 13 36 12.1046 36 11C36 9.89543 35.1046 9 34 9C32.8954 9 32 9.89543 32 11C32 12.1046 32.8954 13 34 13Z"/>
        </svg>);
}
const baseStyles = css(iconStyles, {
    width: 20,
    height: 20,
});
const subtleStyles = css(baseStyles, {
    fill: themeTokens.color.icon.subtle,
});
const mutedStyles = css(baseStyles, {
    fill: themeTokens.color.icon.muted,
});
