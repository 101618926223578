//Enums can't be keys in a dictionary so we might need to convert this to a list of static strings
export enum Permission {
    None = "None",
    AdministerSystem = "AdministerSystem",
    ProjectEdit = "ProjectEdit",
    ProjectView = "ProjectView",
    ProjectCreate = "ProjectCreate",
    ProjectDelete = "ProjectDelete",
    ProcessView = "ProcessView",
    ProcessEdit = "ProcessEdit",
    VariableEdit = "VariableEdit",
    VariableEditUnscoped = "VariableEditUnscoped",
    VariableView = "VariableView",
    VariableViewUnscoped = "VariableViewUnscoped",
    ReleaseCreate = "ReleaseCreate",
    ReleaseView = "ReleaseView",
    ReleaseEdit = "ReleaseEdit",
    ReleaseDelete = "ReleaseDelete",
    DefectReport = "DefectReport",
    DefectResolve = "DefectResolve",
    DeploymentCreate = "DeploymentCreate",
    DeploymentDelete = "DeploymentDelete",
    DeploymentView = "DeploymentView",
    EnvironmentView = "EnvironmentView",
    EnvironmentCreate = "EnvironmentCreate",
    EnvironmentEdit = "EnvironmentEdit",
    EnvironmentDelete = "EnvironmentDelete",
    MachineCreate = "MachineCreate",
    MachineEdit = "MachineEdit",
    MachineView = "MachineView",
    MachineDelete = "MachineDelete",
    ArtifactView = "ArtifactView",
    ArtifactCreate = "ArtifactCreate",
    ArtifactEdit = "ArtifactEdit",
    ArtifactDelete = "ArtifactDelete",
    FeedView = "FeedView",
    EventView = "EventView",
    EventRetentionView = "EventRetentionView",
    EventRetentionDelete = "EventRetentionDelete",
    LibraryVariableSetView = "LibraryVariableSetView",
    LibraryVariableSetCreate = "LibraryVariableSetCreate",
    LibraryVariableSetEdit = "LibraryVariableSetEdit",
    LibraryVariableSetDelete = "LibraryVariableSetDelete",
    ProjectGroupView = "ProjectGroupView",
    ProjectGroupCreate = "ProjectGroupCreate",
    ProjectGroupEdit = "ProjectGroupEdit",
    ProjectGroupDelete = "ProjectGroupDelete",
    TeamCreate = "TeamCreate",
    TeamView = "TeamView",
    TeamEdit = "TeamEdit",
    TeamDelete = "TeamDelete",
    UserView = "UserView",
    UserEdit = "UserEdit",
    UserInvite = "UserInvite",
    UserRoleView = "UserRoleView",
    UserRoleEdit = "UserRoleEdit",
    TaskView = "TaskView",
    TaskCreate = "TaskCreate",
    TaskCancel = "TaskCancel",
    TaskEdit = "TaskEdit",
    InterruptionView = "InterruptionView",
    InterruptionSubmit = "InterruptionSubmit",
    InterruptionViewSubmitResponsible = "InterruptionViewSubmitResponsible",
    BuildInformationPush = "BuildInformationPush",
    BuildInformationAdminister = "BuildInformationAdminister",
    BuiltInFeedPush = "BuiltInFeedPush",
    BuiltInFeedAdminister = "BuiltInFeedAdminister",
    BuiltInFeedDownload = "BuiltInFeedDownload",
    ActionTemplateView = "ActionTemplateView",
    ActionTemplateCreate = "ActionTemplateCreate",
    ActionTemplateEdit = "ActionTemplateEdit",
    ActionTemplateDelete = "ActionTemplateDelete",
    LifecycleCreate = "LifecycleCreate",
    LifecycleView = "LifecycleView",
    LifecycleEdit = "LifecycleEdit",
    LifecycleDelete = "LifecycleDelete",
    AccountView = "AccountView",
    AccountEdit = "AccountEdit",
    AccountCreate = "AccountCreate",
    AccountDelete = "AccountDelete",
    TenantCreate = "TenantCreate",
    TenantEdit = "TenantEdit",
    TenantView = "TenantView",
    TenantDelete = "TenantDelete",
    TagSetCreate = "TagSetCreate",
    TagSetEdit = "TagSetEdit",
    TagSetDelete = "TagSetDelete",
    MachinePolicyCreate = "MachinePolicyCreate",
    MachinePolicyView = "MachinePolicyView",
    MachinePolicyEdit = "MachinePolicyEdit",
    MachinePolicyDelete = "MachinePolicyDelete",
    ProxyCreate = "ProxyCreate",
    ProxyView = "ProxyView",
    ProxyEdit = "ProxyEdit",
    ProxyDelete = "ProxyDelete",
    SubscriptionCreate = "SubscriptionCreate",
    SubscriptionView = "SubscriptionView",
    SubscriptionEdit = "SubscriptionEdit",
    SubscriptionDelete = "SubscriptionDelete",
    TriggerCreate = "TriggerCreate",
    TriggerView = "TriggerView",
    TriggerEdit = "TriggerEdit",
    TriggerDelete = "TriggerDelete",
    CertificateView = "CertificateView",
    CertificateCreate = "CertificateCreate",
    CertificateEdit = "CertificateEdit",
    CertificateDelete = "CertificateDelete",
    CertificateExportPrivateKey = "CertificateExportPrivateKey",
    ConfigureServer = "ConfigureServer",
    FeedEdit = "FeedEdit",
    WorkerEdit = "WorkerEdit",
    WorkerView = "WorkerView",
    SpaceView = "SpaceView",
    SpaceEdit = "SpaceEdit",
    SpaceCreate = "SpaceCreate",
    SpaceDelete = "SpaceDelete",
    RunbookView = "RunbookView",
    RunbookEdit = "RunbookEdit",
    RunbookRunView = "RunbookRunView",
    RunbookRunEdit = "RunbookRunEdit",
    RunbookRunCreate = "RunbookRunCreate",
    GitCredentialView = "GitCredentialView",
    GitCredentialEdit = "GitCredentialEdit",
    InsightsReportView = "InsightsReportView",
    InsightsReportCreate = "InsightsReportCreate",
    InsightsReportEdit = "InsightsReportEdit",
    InsightsReportDelete = "InsightsReportDelete"
}
