import type { Client } from "../client";
import type { DeploymentResource, CreateBulkDeploymentBffCommand, CreateBulkDeploymentBffResponse, CreateDeploymentResource } from "../resources";
import type { GetDeploymentProcessActionTypesBffResponse } from "../resources/getDeploymentProcessActionTypesBffResponse";
import type { TaskState } from "../resources/taskState";
import type { ListArgs } from "./basicRepository";
import { BasicRepository } from "./basicRepository";
type DeploymentListArgs = {
    projects?: string[];
    environments?: string[];
    tenants?: string[];
    channels?: string[];
    taskState?: TaskState;
} & ListArgs;
export class DeploymentRepository extends BasicRepository<DeploymentResource, DeploymentResource, DeploymentListArgs> {
    constructor(client: Client) {
        super("Deployments", client);
    }
    override create(resource: DeploymentResource, args?: {}): Promise<DeploymentResource> {
        return this.client.post<DeploymentResource>(this.client.getLink(this.collectionLinkName), resource, args);
    }
    createBulkDeployment(deployments: CreateDeploymentResource[], formValues: {
        [p: string]: string;
    } | null): Promise<CreateBulkDeploymentBffResponse> {
        const deployment = deployments[0];
        const command: CreateBulkDeploymentBffCommand = {
            ReleaseId: deployment.ReleaseId,
            ProjectId: deployment.ProjectId,
            ForcePackageDownload: deployment.ForcePackageDownload,
            ForcePackageRedeployment: deployment.ForcePackageRedeployment,
            SkipActions: deployment.SkipActions,
            UseGuidedFailure: deployment.UseGuidedFailure,
            FormValues: formValues,
            QueueTime: deployment.QueueTime,
            QueueTimeExpiry: deployment.QueueTimeExpiry,
            RequestedDeployments: deployments.map((d) => ({
                EnvironmentId: d.EnvironmentId,
                TenantId: d.TenantId,
                SpecificMachineIds: d.SpecificMachineIds,
                ExcludedMachineIds: d.ExcludedMachineIds,
            })),
        };
        return this.client.post<CreateBulkDeploymentBffResponse>(`~/bff/spaces/{spaceId}/bulkdeployment`, command);
    }
    getDeploymentProcessActionTypes(deploymentProcessId: string): Promise<GetDeploymentProcessActionTypesBffResponse> {
        return this.client.get<GetDeploymentProcessActionTypesBffResponse>(`~/bff/spaces/{spaceId}/deploymentProcess/actionTypes?deploymentProcessId=${encodeURIComponent(deploymentProcessId)}`);
    }
}
