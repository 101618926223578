import { css, cx } from "@emotion/css";
import { MenuItem, useForkRef } from "@material-ui/core";
import { space } from "@octopusdeploy/design-system-tokens";
import type { PropsWithChildren, Ref } from "react";
import React, { forwardRef, useEffect, useRef } from "react";
import { menuItemClasses, menuItemStyles } from "../menuItemStyles";
export interface MenuItemDownloadLinkProps {
    href: string;
    downloadFileName: string;
    label: string;
    onClick?: () => void;
    autoFocus?: boolean;
    compact?: boolean;
}
export const MenuItemDownloadLink = forwardRef(({ href, label, onClick, autoFocus, compact, downloadFileName }: MenuItemDownloadLinkProps, ref: Ref<HTMLAnchorElement>) => {
    // We can't use the default li component that MenuItem renders here, because the parent element (CustomMenu or MenuList) is not a ul
    return <MenuItem component={LinkWrapper} href={href} label={label} onClick={onClick} autoFocus={autoFocus} compact={compact} ref={ref} downloadFileName={downloadFileName}/>;
});
MenuItemDownloadLink.displayName = "MenuItemDownloadLink";
interface LinkWrapperProps {
    href: string;
    onClick: undefined | (() => void);
    label: string;
    className: string;
    autoFocus: boolean | undefined;
    compact: boolean | undefined;
    downloadFileName: string;
}
const LinkWrapper = forwardRef(({ href, label, onClick, className, children, autoFocus, compact, downloadFileName, ...other }: PropsWithChildren<LinkWrapperProps>, ref: Ref<HTMLAnchorElement>) => {
    const classes = cx(className, menuItemLinkStyles, compact ? compactMenuItemStyles : nonCompactMenuItemStyles);
    const focusRef = useRef<HTMLAnchorElement | null>(null);
    const combinedRef = useForkRef(focusRef, ref);
    useEffect(() => {
        if (autoFocus) {
            focusRef.current?.focus();
        }
    }, [autoFocus]);
    // Ideally we would wrap an anchor tag in an li like in this example: https://www.w3.org/TR/wai-aria-practices/examples/menu-button/menu-button-links.html
    // <li role="none"><a role="menuitem">My Link</a></li>
    // But if we do that, we lose out on keyboard navigation support from material ui's MenuList component
    // Instead, we want to render an anchor tag so that we get the right native browser link behaviours,
    // but make it appear like a menuitem for the accessibility tree
    return (
    // We don't want to use the ExternalLink component here because there are some things that are specific to menu items that we don't want to expose on the ExternalLink component
    // For example, being able to customise the role, or passing generic classes through to the anchor tag (e.g. classes provided by material-ui's MenuItem component)
    // eslint-disable-next-line react/forbid-elements
    <a aria-disabled={undefined} target={"_blank"} rel={"noopener noreferrer"} role={"menuItem"} onClick={onClick} href={href} tabIndex={-1} className={classes} {...other} download={downloadFileName} ref={combinedRef}>
            {label}
            {/*The children is the ripple effect*/}
            {children}
        </a>);
});
LinkWrapper.displayName = "LinkWrapper";
const menuItemLinkStyles = css(menuItemStyles, {
    [`&.${menuItemClasses.root}`]: {
        "&:hover": {
            color: "inherit",
        },
    },
});
const nonCompactMenuItemStyles = css({
    [`&.${menuItemClasses.root}`]: { minHeight: space[48] },
});
const compactMenuItemStyles = css({
    [`&.${menuItemClasses.root}`]: { minHeight: "auto" },
});
